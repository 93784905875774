<template>
  <div class="register-wrap">
    <div class="register content_area">
      <div class="tc title">Registration Information</div>
      <div class="form-wrap">
        <el-form
          size="small"
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="120px"
          class="demo-ruleForm"
        >
          <el-row :gutter="20" class="mb20">
            <el-col :span="12">
              <el-form-item label="Name" prop="name">
                <el-input
                  style="width: 300px"
                  v-model="ruleForm.name"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Company" prop="company">
                <el-input
                  @focus="openCompany"
                  :disabled="companyExists"
                  style="width: 300px"
                  v-model="ruleForm.company"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="mb20">
            <el-col :span="12">
              <el-form-item label="Position" prop="position">
                <el-input
                  style="width: 300px"
                  v-model="ruleForm.position"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item class="gender-wrap" label="Gender" prop="gender">
                <el-radio-group v-model="ruleForm.gender" size="small">
                  <el-radio-button class="male" label="1">
                    Male
                  </el-radio-button>
                  <el-radio-button class="female" label="2">
                    Female
                  </el-radio-button>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="mb20">
            <el-col :span="12">
              <el-form-item label="Mobile" prop="mobile">
                <el-input style="width: 300px" v-model="ruleForm.mobile">
                  <el-select
                    style="width: 90px"
                    v-model="ruleForm.code"
                    slot="prepend"
                    class="companyTelephoneList"
                    placeholder="Select"
                    popper-class="registerAreaCodeList"
                    filterable
                    :filter-method="areaCodeFilter"
                  >
                    <el-option
                      v-for="(item, index) in areaCodeList"
                      :key="item.area_code + index + ''"
                      :value="item.area_code"
                      :title="item.country | areacodeTitleFormat(LOCALE)"
                    >
                      <p class="area_code">{{ item.area_code }}</p>
                      <p class="country textOverflow">
                        {{ item.country | areacodeAddressFormat(LOCALE) }}
                      </p>
                    </el-option>
                  </el-select>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Email" prop="email">
                <el-input
                  :disabled="emailDisabled"
                  style="width: 300px"
                  v-model="ruleForm.email"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="mb20 tc btn">
            <el-button
              :loading="loading"
              size="small"
              type="primary"
              @click="submitForm('ruleForm')"
            >
              Register now</el-button
            >
          </div>
        </el-form>
      </div>
      <register ref="registerCompany" @confirmJoin="confirmJoin" />
    </div>
  </div>
</template>

<script>
import fileUpload from "~/baseComponents/fileUpload";
import register from "@/baseComponents/registerCompany";
import { priorFormat } from "@/basePlugins/filters";
import { areaCodeList } from "~/baseUtils/AddressConfig";
export default {
  components: {
    fileUpload,
    register,
  },
  data() {
    return {
      areaCodeList,
      loading: false,
      ruleForm: {
        name: null,
        company: null,
        position: null,
        code: null,
        mobile: null,
        gender: null,
        email: null,
      },
      rules: {
        name: {
          required: true,
          message: "Please enter name",
          trigger: blur,
        },
        company: {
          required: true,
          message: "Please enter the company name",
          trigger: blur,
        },
        position: {
          required: true,
          message: "Please enter Position",
          trigger: blur,
        },
        gender: {
          required: true,
          message: "Please enter Gender",
          trigger: blur,
        },
        email: {
          required: true,
          message: "Please Enter your email",
          trigger: blur,
        },
      },
      fileUploadOptions: {
        autoUpload: true,
        accept: ".jpg,.jpeg,.bmp,.png",
      },
      companyExists: false,
      selectCompanyInfo: {},
      companyEnrollInfo: null,
      company_id: null,
      emailDisabled: false,
    };
  },
  created() {
    if (this.USER_INFO.company_id) {
      this.company_id = this.USER_INFO.company_id;
      this.companyExists = true;
    } else {
      this.companyExists = false;
    }
    let companyName =
      this.USER_INFO.company_name_en || this.USER_INFO.company_name_zh;
    this.ruleForm.company = companyName;
    let name = this.USER_INFO.name_en || this.USER_INFO.name_zh;
    this.ruleForm.name = name;
    let job = this.USER_INFO.job_title_en || this.USER_INFO.job_title_zh;
    this.ruleForm.position = job;
    if (this.USER_INFO.sex) {
      this.ruleForm.gender = this.USER_INFO.sex;
    }
    if (this.USER_INFO.area_code) {
      this.ruleForm.code = this.USER_INFO.area_code;
    }
    console.log(this.USER_INFO);
    if (this.USER_INFO.mobile) {
      this.ruleForm.mobile = this.USER_INFO.mobile;
    }
    if (this.USER_INFO.email) {
      this.ruleForm.email = this.USER_INFO.email;
      this.emailDisabled = true;
    }
  },
  mounted() {
    document.title = "WFA BI-LATERAL VIRTUAL MEETINGS";
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitFormFn();
        } else {
          return false;
        }
      });
    },
    async submitFormFn() {
      this.loading = true;
      if (this.$store.state.baseStore.userInfo.company_id) {
        let params = {};
        params.meeting_id = this.MEETING_ID;
        params.company_id = this.USER_INFO.company_id;
        params.apply_type = 4;
        let result = await this.$store.dispatch(
          "register/companyJoinMeeting",
          params
        );
        if (result.success) {
          this.userRegister();
        } else {
          this.loading = false;
        }
      } else {
        let params = {};
        if (this.selectCompanyInfo.company_id) {
          params.company_id = this.selectCompanyInfo.company_id;
          if (this.selectCompanyInfo.country) {
            params.country = this.selectCompanyInfo.country;
          }
          if (this.selectCompanyInfo.region_en) {
            params.region_en = this.selectCompanyInfo.region_en;
          }
          if (this.selectCompanyInfo.region_zh) {
            params.region_zh = this.selectCompanyInfo.region_zh;
          }
        } else {
          params.company_name_en = this.ruleForm.company;
          params.region_en = this.selectCompanyInfo.region_en;
          params.country = this.selectCompanyInfo.country;
        }
        params.user_id = this.USER_INFO.id;
        params.source = this.PJSource;
        let res = await this.$store.dispatch(
          "register/companyRegister",
          params
        );
        if (res.success) {
          if (
            this.companyEnrollInfo &&
            this.companyEnrollInfo.is_company_enroll
          ) {
            this.userRegister();
            return;
          }
          this.company_id = res.data;
          let params = {};
          params.meeting_id = this.MEETING_ID;
          params.company_id = this.company_id;
          params.apply_type = 4;
          let result = await this.$store.dispatch(
            "register/companyJoinMeeting",
            params
          );
          if (result.success) {
            this.userRegister();
          } else {
            this.loading = false;
          }
        } else {
          this.loading = false;
        }
      }
    },
    async userRegister() {
      let params = {};
      console.log(this.company_id);
      params.meeting_id = this.MEETING_ID;
      params.company_id = this.company_id;
      params.user_id = this.USER_INFO.id;
      params.apply_type = 4;
      params.name_en = this.ruleForm.name;
      params.job_title_en = this.ruleForm.position;
      params.sex = this.ruleForm.gender;
      params.area_code = this.ruleForm.code;
      params.mobile = this.ruleForm.mobile;
      params.email = this.ruleForm.email;
      params.company_id = this.company_id;
      params.status = 1;
      params.audit_status = 1;
      let res = await this.$store.dispatch("register/userJoinMeeting", params);
      if (res.success) {
        this.loading = false;
        this.goToSuccess();
      } else {
        this.loading = false;
      }
    },
    async goToSuccess() {
      this.updateUserAndEnrollInfo();
      this.$router.push({
        path: "/registerSuccess",
      });
    },
    async updateUserAndEnrollInfo() {
      let result = await this.$store.dispatch(
        "baseStore/baseSign_getUserInfo",
        {
          user_id: this.USER_INFO.id,
        }
      );
      let data = await this.$store.dispatch("baseStore/baseSign_getUserAuth", {
        user_id: this.USER_INFO.id,
      });
      let enrollInfo = await this.$store.dispatch(
        "baseStore/baseSign_getMeetingEnrollInfo",
        {
          meeting_id: this.MEETING_ID,
          user_id: this.USER_INFO.id,
          company_id: this.company_id,
        }
      );
    },
    openCompany() {
      this.$refs.registerCompany.openPanel();
    },
    async confirmJoin(val) {
      this.ruleForm.company = val.name;
      if (val.result.company_id) {
        this.company_id = val.result.company_id;
        let query = {};
        query.company_id = val.result.company_id;
        query.meeting_id = this.MEETING_ID;
        let enrollRes = await this.$store.dispatch(
          "register/companyRegisterInfo",
          query
        );
        if (enrollRes.success) {
          this.companyEnrollInfo = enrollRes.data;
        }
      }
      this.selectCompanyInfo = val.result;
      this.$refs.registerCompany.closePanel();
    },
    areaCodeFilter(value) {
      if (value) {
        this.areaCodeList = this.areaCodeListCopy
          .filter((item) => {
            return item.area_code.indexOf(value) == 1;
          })
          .sort((a, b) => {
            return parseInt(a.area_code) - parseInt(b.area_code);
          });
      } else {
        this.areaCodeList = this.areaCodeListCopy;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.register-wrap {
  height: calc(100vh - 62px);
  background: #f6f7f9;
  padding-top: 23px;
}

.register {
  box-sizing: border-box;
  background-color: #fff;
  padding: 40px 150px;

  .title {
    font-weight: 600;
    font-size: 16px;
    margin: 0 0 50px 30px;
  }

  .form-wrap {
    .demo-ruleForm {
      .btn {
        /deep/ .el-button {
          width: 300px;
        }
      }

      .gender-wrap {
        /deep/ .el-radio-button__inner {
          padding: 0;
          width: 135px;
          height: 32px;
          line-height: 32px;
          border-radius: 4px;
          border: 1px solid #dcdfe6;
        }

        .male {
          margin-right: 30px;
        }

        .female.is-active {
          /deep/ .el-radio-button__inner {
            box-shadow: none;
          }
        }
      }
    }
  }
}

.mb20 {
  margin-bottom: 40px;
}
</style>
